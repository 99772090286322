import { createHubSpotContact } from 'lib/utils'
import React from 'react'
import { CustomerSupport } from 'components/Idengager'

export default function SupportQuestions() {
    return (
        <div className="mt-12 max-w-[600px]">
            <h3 id="idengager-questions" className="mb-4">
                Questions?
            </h3>
            <CustomerSupport />
        </div>
    )
}
